
import { API_TOKEN } from "../constants/environment";

export async function getBaseRequestData() {
  let properties = {};
  await Word.run(async (context) => {
    let recordNumber = context.document.properties.customProperties.getItemOrNullObject("recordNumber");
    let RecordDocument = context.document.properties.customProperties.getItemOrNullObject("RecordDocument");
    let template = context.document.properties.customProperties.getItemOrNullObject("HorusTemplate");
    let ExternalEdition = context.document.properties.customProperties.getItemOrNullObject("ExternalEditionId");
    let UserId = context.document.properties.customProperties.getItemOrNullObject("UserId");


    await context.load(recordNumber);
    await context.load(RecordDocument);
    await context.load(template);
    await context.load(ExternalEdition);
    await context.load(UserId);

    await context.sync();

    properties = {
        recordNumber: recordNumber.value,
        RecordDocument: RecordDocument.value,
        template: template.value,
        ExternalEdition: ExternalEdition.value,
        UserId: UserId.value,
    };
  });

  return properties;
}

export async function getBaseRequestHeader() {
  let headers = {};
  await Word.run(async (context) => {
    let token = context.document.properties.customProperties.getItemOrNullObject("Token");

    await context.load(token);
    await context.sync();

    headers = {
        Authorization: token.value,
        "Access-Control-Allow-Origin": "*"
    };
  });

  return headers;
}

export async function getAPIUrl() {
    let apiUrl;
    await Word.run(async (context) => {
      let apiUrlProp = context.document.properties.customProperties.getItemOrNullObject("ApiUrl");
      await context.load(apiUrlProp);
  
      await context.sync();
  
      apiUrl = apiUrlProp.value;
    });
  
    return apiUrl;
  }
  
  export async function getRecordNumber() {
    let recordNumberValue;
    await Word.run(async (context) => {
      let recordNumber = context.document.properties.customProperties.getItemOrNullObject("recordNumber");
      await context.load(recordNumber);
  
      await context.sync();
  
      recordNumberValue = recordNumber.value;
    });
  
    return recordNumberValue;
  }
  

